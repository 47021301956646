.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #f23b4d;
  border: 1px double #f23b4d;
}

.CalendarDay__selected_span,
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #ffb0b4;
  border: 1px double #ff7885;
}

.CalendarDay__selected_span:hover {
  background: #ff7885;
  border: 1px double #ff7885;
}

.DateInput_input__focused {
  border-bottom: 2px solid #f23b4d;
}

.DateRangePicker_picker {
  z-index: 100;
}

.DateInput_input {
  font-weight: 700;
  font-size: 30px;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}

.DateInput {
  width: 180px;
  margin: 24px 0 16px;
}

.DateRangePickerInput_arrow_svg {
  margin: 0 12px;
}

.DateRangePickerInput__disabled,
.DateInput_input__disabled,
.DateInput__disabled {
  background: none;
  font-style: normal;
  color: #a6a6a6;
}

.DateInput__disabled {
  margin: 24px 0 16px;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}
